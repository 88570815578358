d<template>
  <section>
    <b-card
      no-body
      class="mb-0"
      header-tag="header"
    >
      <div class="card-body">
        <b-card>
          <b-row>
            <b-col cols="12">
              <b-form-group
                id="input-group-1"
                label="Báo cáo"
                label-for="input-1"
              >
                <treeselect
                  id="linhVuc"
                  v-model="baoCaoId"
                  v-format-v-select
                  :options="baoCaoCbx"
                  :limit="1"
                  :limit-text="(count) => ` + ${count} lựa chọn`"
                  no-options-text="Không có dữ liệu"
                  no-results-text="Không có dữ liệu"
                  placeholder="-- Chọn báo cáo --"
                  :clear-on-select="true"
                  :normalizer="normalizer"
                  :match-keys="['label', 'label2']"
                  @input="getDanhSachLinhVuc() , getDonViTheoDonViGoc()"
                >
                  <label
                    slot="option-label"
                    slot-scope="{ node, shouldShowCount, count, labelClassName }"
                    :class="labelClassName"
                    :title="node.label"
                  >
                    - {{ node.label }}
                  </label>
                </treeselect>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-card class="border">
                <div>
                  <div class="d-flex align-items-center">
                    <span class="pl-3 text-form">LĨNH VỰC</span>
                    <span
                      class="pl-3 text-link"
                      @click="chonTatCaLinhVuc"
                    >
                      Chọn tất cả
                    </span>
                    <span
                      class="pl-3 text-link"
                      @click="boChonTatCaLinhVuc"
                    >
                      Bỏ chọn tất cả
                    </span>
                  </div>
                  <div><hr></div>
                  <div v-if="baoCaoCbx">
                    <div class="body-card">
                      <b-row>
                        <b-col
                          v-for="linhVuc in linhVucCbx"
                          :key="linhVuc.id"
                          cols="6"
                          class="d-flex mt-1"
                        >
                          <b-form-checkbox
                            v-model="linhVuc.check"
                            class="ml-2"
                            @input="inputLinhVuc($event, linhVuc.id)"
                          />
                          <span>{{ linhVuc.name }}</span>

                        </b-col>
                      </b-row>
                    </div>
                  </div>
                </div>

              </b-card>
            </b-col>
            <b-col cols="6">
              <b-card class="border">
                <div>
                  <div class="d-flex align-items-center">
                    <span class="pl-3 text-form">ĐƠN VỊ</span>
                    <span
                      class="pl-3 text-link"
                      @click="chonTatCaDonVi"
                    >
                      Chọn tất cả
                    </span>
                    <span
                      class="pl-3 text-link"
                      @click="boChonTatCaDonVi"
                    >
                      Bỏ chọn tất cả
                    </span>
                  </div>
                  <div><hr></div>
                  <div class="body-card">
                    <b-row>
                      <b-col
                        v-for="donVi in danhSachDonVi"
                        :key="donVi.donViId"
                        cols="6"
                        class="d-flex mt-1"
                      >
                        <b-form-checkbox
                          v-model="donVi.check"
                          class="ml-2"
                          @input="inputDonVi($event, donVi.donViId)"
                        />
                        <span>{{ donVi.name }}</span>
                      </b-col>
                    </b-row>
                  </div>
                </div>

              </b-card>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              class="border-card"
            >
              <b-card class="border">
                <div class="pl-3">
                  <div class="mb-1">
                    <span class="text-form">THỜI GIAN</span>
                  </div>
                  <div class="mb-2 d-flex wrap-control flex-wrap">
                    <div class="control-label">
                      Năm
                    </div>
                    <treeselect
                      v-model="time.year"
                      v-format-v-select
                      :options="YEARS()"
                      class="select-year"
                      :disabled="activeTimeSelect === 4 ? true : false"
                      placeholder="Chọn năm"
                      :normalizer="normalizer"
                      no-options-text="Không có dữ liệu"
                      no-results-text="Không có dữ liệu"
                    />
                  </div>
                  <div class="mb-2 wrap-control">
                    <div class="control-label">
                      Thời gian
                    </div>
                    <span>
                      <b-button
                        v-for="btn in timeOption"
                        :key="btn.id"
                        :variant="btn.active ? 'outline-danger' : 'outline-white'"
                        class="mr-1"
                        @click="activeTime(btn.id)"
                      >
                        {{ btn.label }}
                      </b-button>
                    </span>
                  </div>
                  <div
                    v-if="activeTimeSelect === 1"
                    class="mb-2 wrap-control"
                  >
                    <div class="control-label">
                      Tháng
                    </div>
                    <div class="mt-1">
                      <b-form-radio-group
                        v-model="time.month"
                        class="radio-group"
                        :options="MONTHS"
                      />
                    </div>

                  </div>
                  <div
                    v-if="activeTimeSelect === 2"
                    class="wrap-control mb-2"
                  >
                    <div class="control-label">
                      Quý
                    </div>
                    <b-form-radio-group
                      v-model="time.quarter"
                      class="radio-group"
                      :options="QUARTERS"
                    />
                  </div>
                  <div
                    v-if="activeTimeSelect === 4"
                    class="wrap-control"
                  >
                    <div class="control-label">
                      Ngày
                    </div>
                    <date-picker
                      v-model="time.date"
                      range
                      class="w-100 picker-filter"
                      format="DD/MM/YYYY"
                      :disabled-date="disabledYear"
                    />
                  </div>
                </div>

              </b-card>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <div class="d-sm-flex justify-content-end">
                <b-button
                  class="mr-1"
                  variant="danger"
                  :disabled="disableButton()"
                  @click="xemBaoCao()"
                >
                  <eye-icon
                    size="14"
                    class="custom-class"
                    :stroke-width="3"
                  />
                  Xem báo cáo
                </b-button>
                <b-button
                  class="mr-1"
                  variant="warning"
                  :disabled="baoCaoId ? false : true"
                  @click="ketXuatBaoCao()"
                >
                  <external-link-icon
                    size="14"
                    class="custom-class"
                    :stroke-width="3"
                  />
                  Kết xuất
                </b-button>
                <b-button
                  variant="primary"
                  class="btn-primary"
                  :disabled="disableButton()"
                  @click="onPrint()"
                >
                  <printer-icon
                    size="14"
                    class="custom-class"
                    :stroke-width="3"
                  />
                  In
                </b-button>
              </div>
            </b-col>
          </b-row>
          <div>
            <div class="text">
              <b-row>
                <b-col cols="4">
                  <div>
                    <h4>
                      Biểu số 06a/VPCP/KSTT
                    </h4>

                  </div>
                </b-col>
                <b-col cols="4">
                  <div class="text-center">
                    <h3>TÌNH HÌNH, KẾT QUẢ GIẢI QUYẾT THỦ TỤC HÀNH CHÍNH TẠI CƠ QUAN,</h3>
                    <h3>ĐƠN VỊ  GIẢI QUYẾT THỦ TỤC HÀNH CHÍNH</h3>
                  </div>
                </b-col>
                <b-col cols="4">
                  <div />
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <div class="text-center mt-1">
                    <h3>
                      Kỳ báo cáo : {{ getTimeSelected() }}
                    </h3>

                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <div class="text-center">
                    <h3>
                      (Từ ngày {{ formatDate(filter.tuNgay) }} đến ngày {{ formatDate(filter.denNgay) }})
                    </h3>
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <div class="d-flex justify-content-end">
                    <h3>
                      Đơn vị tính : Số hồ sơ TTHC
                    </h3>

                  </div>
                </b-col>
              </b-row>
            </div>
            <div ref="docBody">
              <app-table
                class="mt-1"
                :columns="columns"
                :table-data="tableData"
                :pagination="false"
              />
            </div>
          </div>
        </b-card>
      </div>
      <common-modal
        ref="commonModal"
        :title="title"
        size="xl"
      >
        <good-table
          ref="hoSo"
          class="mt-1"
          :columns="columnsDs"
          :rows="rows"
          :total="tongSoHoSo"
          :selectable="false"
          @page-change="layDanhSachHoSo()"
        >
          <template
            slot="custom-row"
            slot-scope="props"
          >
            <div
              v-if="props.props.column.field === 'ngayNop'"
              class="custom-column"
            >
              <p>{{ formatDateTime(props.props.row.ngayNop) }}</p>
            </div>

            <div
              v-else-if="props.props.column.field === 'ngayHenTraKq'"
              class="custom-column"
            >
              <p>{{ formatDateTime(props.props.row.ngayHenTraKq) }}</p>
            </div>
            <span v-else-if="props.props.column.field == 'code'">
              <a
                href="javascript:;"
                @click="detailHoSo(props.props.row)"
              >{{ `${props.props.row.code}` }}</a>
              <div>
                <cloud-icon
                  size="15"
                  class="custom-class"
                /> {{ props.props.row.kieuTiepNhan }}
              </div>
              <div v-if="props.props.row.dichVuChuyenPhatKetQua">
                <send-icon
                  size="15"
                  class="custom-class"
                /> Dịch vụ chuyển phát kết quả
              </div>
            </span>
            <div
              v-else-if="props.props.column.field === 'tenLoaiTrangThai'"
              class="custom-column"
            >
              <div v-if="props.props.row.tenTrangThai && !props.props.row.tenTrangThai.indexOf('Kết thúc')">
                <div class="text-yellow-cor">
                  {{ props.props.row.tenTrangThai }}
                </div>

              </div>
              <div v-else>
                <div>{{ formatDateTime(props.props.row.thoiGianBatDauTrangThai) }}</div>
                <div>{{ formatDateTime(props.props.row.thoiGianKetThucTrangThai) }}</div>
                <div class="text-yellow-cor">
                  {{ props.props.row.tenTrangThai }}
                </div>
                <div
                  v-if="props.props.row.thoiGianDenHanKetThucTrangThai"
                  :class="props.props.row.thoiGianDenHanKetThucTrangThai.indexOf('Còn hạn') >= 0 ? 'text-green-cor' : 'text-red-cor'"
                >
                  {{ props.props.row.thoiGianDenHanKetThucTrangThai }}
                </div>
              </div>

            </div>
            <div
              v-else-if="props.props.column.field === 'chuHoSo_DiaChiDayDu'"
            >
              {{ props.props.row.chuHoSo_DiaChiDayDu }}
            </div>
            <div v-else-if="props.props.column.field === 'chuHoSo_HoVaTen'">
              {{ props.props.row.chuHoSo_HoVaTen }}
            </div>
            <div v-else-if="props.props.column.field === 'tenThuTucHanhChinh'">
              {{ props.props.row.tenThuTucHanhChinh }}
            </div>
          </template>
          <template
            slot="actions"
            slot-scope="props"
          >
            <div
              class="text-center cursor-pointer text-link"
              title="Xem chi tiết"
              @click="detailHoSo(props.props.row)"
            >
              Xem chi tiết
            </div>

          </template>
        </good-table>
        <div
          slot="footer"
        >
          <b-button
            variant="primary"
            size="sm"
            class="mr-2"
            @click="ketXuatDanhSachHoSo()"
          >
            Kết xuất
          </b-button>
          <b-button
            variant="danger"
            size="sm"
            @click="$refs.commonModal.hideModal()"
          >
            Đóng
          </b-button>
        </div>
      </common-modal>
      <iframe
        id="printing-frame"
        name="print_frame"
        style="display:none;"
      />
    </b-card>
  </section>
</template>

<script>
import {
  BCard,
  BFormGroup,
  BFormCheckbox,
  BRow,
  BCol,
  BButton,
  BFormRadioGroup,
  VBTooltip,
} from 'bootstrap-vue'
import { PER_PAGE_OPTIONS } from '@/constants/constants'
import DatePicker from 'vue2-datepicker'
import {
  CloudIcon,
  SendIcon,
  ExternalLinkIcon,
  PrinterIcon,
  EyeIcon,
} from 'vue-feather-icons'
import commonModal from '@/modules/tiep-nhan-ho-so/components/modal/CommonModal.vue'
import addCssSemantic from '@/mixins/mixins'
import END_POINTS from '@/api/endpoints'
import AppTable from '@/components/AppTable.vue'
import GoodTable from '@/components/GoodTable.vue'
import 'vue2-datepicker/index.css'
import {
  removeDiacritical, formatDateTime, formatDateRequest, formatDate, formatNumberToCost,
} from '@/utils/index'
import _filter from 'lodash/filter'
import _isEmpty from 'lodash/isEmpty'
import { YEARS, QUARTERS, MONTHS } from '../../constants/constants'

export default {
  name: 'BaoCaoThongKe',
  components: {
    BFormGroup,
    CloudIcon,
    SendIcon,
    commonModal,
    BCard,
    AppTable,
    ExternalLinkIcon,
    PrinterIcon,
    EyeIcon,
    BFormRadioGroup,
    BButton,
    BFormCheckbox,
    BRow,
    BCol,
    GoodTable,
    DatePicker,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [addCssSemantic],
  data() {
    return {
      YEARS,
      QUARTERS,
      MONTHS,
      perPageOptions: PER_PAGE_OPTIONS,
      optionsLinhVuc: [],
      option: [],
      baoCaoCbx: [],
      baoCaoId: null,
      linhVucCbx: [],
      donViCbx: [],
      baoCao_Cbx: [],
      rows: [],
      totalPages: 0,
      selected: '',
      totalRows: 0,
      isLoading: false,
      selectedItems: [],
      danhSachThuTucHanhChinh: [],
      danhSachLinhVuc: [],
      danhSachDonVi: [],
      tongSoHoSo: 0,
      total: 0,
      title: 'Danh sách hồ sơ',
      pageChange: [],
      danhSachTrangThaiHoSo: [],
      danhSachHoSo: [],
      columnsDs: [
        {
          label: 'STT',
          thClass: 'text-center custom-style',
          tdClass: 'text-center',
          width: '50px',
          field: 'STT',
        },
        {
          label: 'Số biên nhận',
          field: 'code',
          width: '200px',
          thClass: 'text-center',
        },
        {
          label: 'Lĩnh vực',
          field: 'tenLinhVuc',
          width: '200px',
          thClass: 'text-center',
        },
        {
          label: 'Tên thủ tục hành chính',
          field: 'tenThuTucHanhChinh',
          width: '250px',
          thClass: 'text-center',
        },
        {
          label: 'Chủ hồ sơ',
          field: 'chuHoSo_HoVaTen',
          width: '150px',
          thClass: 'text-center',
        },
        {
          label: 'Số điện thoại',
          field: 'chuHoSo_SoDienThoai',
          width: '150px',
          thClass: 'text-center',
        },
        {
          label: 'Địa chỉ',
          field: 'chuHoSo_DiaChiDayDu',
          width: '250px',
          thClass: 'text-center',
        },
        {
          label: 'Ngày nộp',
          field: 'ngayNop',
          width: '250px',
          thClass: 'text-center',
        },
        {
          label: 'Ngày hẹn trả',
          field: 'ngayHenTraKq',
          width: '150px',
          thClass: 'text-center',
        },
        {
          label: 'Thao tác',
          tdClass: 'vertical-align-middle',
          thClass: 'text-center',
          field: 'actions',
          width: '100px',
        },
      ],
      columns: [
        {
          field: 'STT',
          key: 'a',
          title: 'STT',
          width: '2%',
          renderBodyCell: ({ rowIndex }) => rowIndex + 1,

        },
        {
          field: 'tenLinhVuc',
          key: 'b',
          title: 'Lĩnh vực giải quyết',
          width: '9%',
          renderBodyCell: ({ row, column }) => {
            const text = row[column.field]
            return <span><p class="text-check">{text}</p></span>
          },
        },
        {
          field: 'soLuongHoSoTiepNhan',
          key: 'f',
          title: 'Số lượng hồ sơ tiếp nhận',
          width: '10%',
          children: [
            {
              field: 'tiepNhan_TongSo',
              key: 'g',
              title: 'Tổng số',
              width: '5%',
              renderBodyCell: ({ row, column }) => <span class="custom-cell" onclick={() => this.layDanhSachHoSo(row.tiepNhan_TongSo_HoSoId, row.tiepNhan_TongSo)}><div class="text-check-right">{formatNumberToCost(row[column.field])}</div></span>,
            },
            {
              field: 'trongKy',
              key: 'h',
              title: 'Trong kỳ',
              width: '5%',
              children: [
                {
                  field: 'tiepNhan_TrongKy_TrucTuyen',
                  key: 'cv',
                  title: 'Trực tuyến',
                  width: '3%',
                  renderBodyCell: ({ row, column }) => <span class="custom-cell" onclick={() => this.layDanhSachHoSo(row.tiepNhan_TrongKy_TrucTuyen_HoSoId, row.tiepNhan_TrongKy_TrucTuyen)}><div class="text-check-right">{formatNumberToCost(row[column.field])}</div></span>,
                }, {
                  field: 'tiepNhan_TrongKy_TrucTiep',
                  key: 'zxt',
                  title: 'Trực tiếp',
                  width: '3%',
                  renderBodyCell: ({ row, column }) => <span class="custom-cell" onclick={() => this.layDanhSachHoSo(row.tiepNhan_TrongKy_TrucTiep_HoSoId, row.tiepNhan_TrongKy_TrucTiep)}><div class="text-check-right">{formatNumberToCost(row[column.field])}</div></span>,
                },
              ],
            },
            {
              field: 'tiepNhan_KyTruoc',
              key: 'i',
              title: 'Từ kỳ trước',
              width: '5%',
              renderBodyCell: ({ row, column }) => <span class="custom-cell" onclick={() => this.layDanhSachHoSo(row.tiepNhan_KyTruoc_HoSoId, row.tiepNhan_KyTruoc)}><div class="text-check-right">{formatNumberToCost(row[column.field])}</div></span>,
            },

          ],
        },
        {
          field: 'soLuongHoSoDaGiaiQuyet',
          key: 'j',
          title: 'Số lượng hồ sơ đã giải quyết',
          width: '10%',
          children: [
            {
              field: 'tongSoDaGiaiQuyet',
              key: 'n',
              title: 'Tổng số',
              width: '5%',
              renderBodyCell: ({ row, column }) => <span class="custom-cell" onclick={() => this.layDanhSachHoSo(row.tongSoDaGiaiQuyet_HoSoId, row.tongSoDaGiaiQuyet)}><div class="text-check-right">{formatNumberToCost(row[column.field])}</div></span>,
            },
            {
              field: 'daGiaiQuyet_TruocHan',
              key: 'm',
              title: 'Trước hạn',
              width: '5%',
              renderBodyCell: ({ row, column }) => <span class="custom-cell" onclick={() => this.layDanhSachHoSo(row.daGiaiQuyet_TruocHan_HoSoId, row.daGiaiQuyet_TruocHan)}><div class="text-check-right">{formatNumberToCost(row[column.field])}</div></span>,
            },
            {
              field: 'daGiaiQuyet_DungHan',
              key: 'tr',
              title: 'Đúng hạn',
              width: '5%',
              renderBodyCell: ({ row, column }) => <span class="custom-cell" onclick={() => this.layDanhSachHoSo(row.daGiaiQuyet_DungHan_HoSoId, row.daGiaiQuyet_DungHan)}><div class="text-check-right">{formatNumberToCost(row[column.field])}</div></span>,
            },
            {
              field: 'daGiaiQuyet_QuaHan',
              key: 'q',
              title: 'Quá hạn',
              width: '5%',
              renderBodyCell: ({ row, column }) => <span class="custom-cell" onclick={() => this.layDanhSachHoSo(row.daGiaiQuyet_QuaHan_HoSoId, row.daGiaiQuyet_QuaHan)}><div class="text-check-right">{formatNumberToCost(row[column.field])}</div></span>,
            },
          ],
        },
        {
          title: 'Số lượng hồ sơ đang giải quyết',
          width: 160,
          children: [
            {
              field: 'dangGiaiQuyet_TongSo',
              title: 'Tổng số',
              key: 'x',
              width: '3%',
              renderBodyCell: ({ row, column }) => <span class="custom-cell" onclick={() => this.layDanhSachHoSo(row.dangGiaiQuyet_TongSo_HoSoId, row.dangGiaiQuyet_TongSo)}><div class="text-check-right">{formatNumberToCost(row[column.field])}</div></span>,
            },
            {
              field: 'dangGiaiQuyet_TrongHan',
              title: 'Trong hạn',
              key: 'y',
              width: '3%',
              renderBodyCell: ({ row, column }) => <span class="custom-cell" onclick={() => this.layDanhSachHoSo(row.dangGiaiQuyet_TrongHan_HoSoId, row.dangGiaiQuyet_TrongHan)}><div class="text-check-right">{formatNumberToCost(row[column.field])}</div></span>,
            },
            {
              field: 'dangGiaiQuyet_QuaHan',
              title: 'Quá hạn',
              key: 'l',
              width: '3%',
              renderBodyCell: ({ row, column }) => <span class="custom-cell" onclick={() => this.layDanhSachHoSo(row.dangGiaiQuyet_QuaHan_HoSoId, row.dangGiaiQuyet_QuaHan)}><div class="text-check-right">{formatNumberToCost(row[column.field])}</div></span>,
            },
          ],
        },
      ],
      tableData: [],
      filter: {
        dataDonViId: [],
        dataLinhVucId: [],
        tuNgay: '',
        denNgay: '',
      },
      time: {
        year: new Date().getFullYear(),
        quarter: null,
        month: null,
        date: null,
      },
      activeTimeSelect: 1,
      timeOption: [
        {
          label: 'Tháng',
          id: 1,
          active: true,
        },
        {
          label: 'Quý',
          id: 2,
          active: false,
        },
        {
          label: 'Năm',
          id: 3,
          active: false,
        },
        {
          label: 'Tuỳ chọn',
          id: 4,
          active: false,
        },
      ],
    }
  },
  created() {
    this.getDanhMucBaoCao()
  },
  methods: {
    formatDateRequest,
    formatNumberToCost,
    formatDateTime,
    formatDate,
    xemBaoCao() {
      if (this.time.year) {
        const date = this.layThoiGianBaoCao()
        this.filter.tuNgay = date.dateBefore
        this.filter.denNgay = date.dateAfter
        this.$axios.post(END_POINTS.BAO_CAO_THONG_KE.LAY_DANH_SACH, this.filter, false).then(response => {
          if (response.data && response.data.succeeded) {
            this.tableData = response.data.data
          }
        })
      }
    },
    ketXuatBaoCao() {
      if (this.time.year) {
        const date = this.layThoiGianBaoCao()
        this.filter.tuNgay = date.dateBefore
        this.filter.denNgay = date.dateAfter
        this.$axios.post(END_POINTS.BAO_CAO_THONG_KE.EXPORT_BAO_CAO, this.filter, false).then(res => {
          if (res.data?.succeeded) {
            this.ketXuat(res)
            this.$toast.success(res.data.message)
          } else {
            this.$toast.error(res.data.message)
          }
        })
      }
    },
    ketXuatDanhSachHoSo() {
      if (this.time.year) {
        this.$axios.post(END_POINTS.BAO_CAO_THONG_KE.EXPORT_HO_SO, this.rows, false).then(res => {
          if (res.data?.succeeded) {
            this.ketXuat(res)
            this.$toast.success(res.data.message)
          } else {
            this.$toast.error(res.data.message)
          }
        })
      }
    },
    ketXuat(res) {
      const docUrl = document.createElement('a')
      docUrl.href = `data:application/octet-stream;base64,${res.data.fileData}`
      docUrl.setAttribute('download', res.data.fileName)
      docUrl.setAttribute('target', '_blank')
      docUrl.click()
    },
    disableButton() {
      if (!this.filter.dataDonViId) {
        return true
      }
      if (!this.filter.dataLinhVucId) {
        return true
      }
      if (!this.baoCaoId) {
        return true
      }
      switch (this.activeTimeSelect) {
        case 1:
          if (!this.time.month && this.time.month !== 0) {
            return true
          }
          return false
        case 2:
          if (!this.time.quarter && this.time.quarter !== 0) {
            return true
          }
          return false
        case 3:
          return false
        case 4:
          if (!this.time.date) {
            return true
          }
          return false
        default: return true
      }
    },
    layThoiGianBaoCao() {
      let dateBefore = new Date()
      let dateAfter = new Date()
      // eslint-disable-next-line default-case
      switch (this.activeTimeSelect) {
        case 1:
          dateBefore.setFullYear(this.time.year)
          dateBefore.setMonth(this.time.month - 1)
          dateBefore.setDate(15)
          dateAfter.setFullYear(this.time.year)
          dateAfter.setMonth(this.time.month)
          dateAfter.setDate(14)
          return {
            dateBefore,
            dateAfter,
          }
        case 2:
          dateBefore.setFullYear(this.time.year)
          dateBefore.setMonth(this.time.quarter - 1)
          dateBefore.setDate(15)
          dateAfter.setFullYear(this.time.year)
          dateAfter.setMonth(this.time.quarter + 2)
          dateAfter.setDate(14)
          return {
            dateBefore,
            dateAfter,
          }
        case 3:
          dateBefore.setFullYear(this.time.year - 1)
          dateBefore.setMonth(11)
          dateBefore.setDate(15)
          dateAfter.setFullYear(this.time.year)
          dateAfter.setMonth(11)
          dateAfter.setDate(14)
          return {
            dateBefore,
            dateAfter,
          }
        case 4:
          if (this.time.date) {
            [dateBefore, dateAfter] = this.time.date
          }
          return {
            dateBefore,
            dateAfter,
          }
        default: return {
          dateBefore,
          dateAfter,
        }
      }
    },
    detailHoSo(data) {
      this.$router.push({ path: '/ho-so/chi-tiet', query: { id: data.id } })
    },
    getDanhMucBaoCao() {
      this.$axios.get(`${END_POINTS.BAO_CAO.COMBOBOX}?phanQuyen=${true}`).then(response => {
        if (response.data && response.data.succeeded) {
          this.baoCaoCbx = response.data.data
        }
      })
    },
    getDanhSachLinhVuc() {
      if (this.baoCaoId) {
        this.$axios.get(`${END_POINTS.LINH_VUC.COMBOBOX}?baoCaoId=${this.baoCaoId}`).then(res => {
          if (res.data && res.data.succeeded) {
            this.linhVucCbx = res.data.data.map(item => ({
              ...item, check: false,
            }))
          }
        })
      } else {
        this.linhVucCbx = null
      }
    },
    getDonViTheoDonViGoc() {
      if (this.baoCaoId) {
        this.$axios.get(`${END_POINTS.CHUNG.DON_VI}?baoCaoId=${this.baoCaoId}`).then(res => {
          if (res.data && res.data.succeeded) {
            this.danhSachDonVi = res.data.data.map(donvi => ({
              id: donvi.donViId,
              name: donvi.tenDonVi,
              ...donvi,
              check: false,
            }))
          }
        })
      } else {
        this.danhSachDonVi = null
      }
    },
    inputLinhVuc(value, id) {
      if (value) {
        this.filter.dataLinhVucId.push(id)
      } else {
        this.filter.dataLinhVucId = this.filter.dataLinhVucId.filter(item => item !== id)
      }
    },
    inputDonVi(value, id) {
      if (value) {
        this.filter.dataDonViId.push(id)
      } else {
        this.filter.dataDonViId = this.filter.dataDonViId.filter(item => item !== id)
      }
    },
    listToTree(array, parent, tree) {
      // eslint-disable-next-line no-param-reassign
      tree = typeof tree !== 'undefined' ? tree : []
      // eslint-disable-next-line no-param-reassign
      parent = typeof parent !== 'undefined' ? parent : { donViId: '00000000-0000-0000-0000-000000000000' }
      const children = _filter(array, child => child.iD_DonVi_Cha === parent.donViId)
      if (!_isEmpty(children)) {
        if (parent.donViId === '00000000-0000-0000-0000-000000000000') {
          // eslint-disable-next-line no-param-reassign
          tree = children
        } else {
          // eslint-disable-next-line no-param-reassign
          parent.children = children
        }
        children.forEach(child => {
          this.listToTree(array, child)
        })
      }
      return tree
    },
    normalizer(node) {
      return {
        id: node.id,
        label: node.name,
        label2: removeDiacritical(node.name),
      }
    },
    normalizer_DonVi(node) {
      if (node.children) {
        return {
          id: node.donViId,
          label: node.tenDonVi,
          children: node.children,
        }
      }
      return {
        id: node.donViId,
        label: node.tenDonVi,
      }
    },
    disabledYear(date) {
      const dateBefore = new Date()
      const dateAfter = new Date()
      if (this.time.year) {
        dateBefore.setFullYear(this.time.year - 1, 11, 14)
        dateAfter.setFullYear(this.time.year, 11, 14)
      }
      return date < dateBefore || date > dateAfter
    },
    activeTime(id) {
      this.activeTimeSelect = id
      this.timeOption.forEach((time, index) => {
        this.timeOption[index].active = false
        if (time.id === id) {
          this.timeOption[index].active = true
        }
      })
    },
    chonTatCaLinhVuc() {
      this.linhVucCbx.forEach((item, index) => {
        this.linhVucCbx[index].check = true
      })
    },
    boChonTatCaLinhVuc() {
      this.filter.dataLinhVucId = []
      this.linhVucCbx.forEach((item, index) => {
        this.linhVucCbx[index].check = false
        return item.id
      })
    },
    chonTatCaDonVi() {
      this.danhSachDonVi.forEach((item, index) => {
        this.danhSachDonVi[index].check = true
      })
    },
    boChonTatCaDonVi() {
      this.filter.dataDonViId = []
      this.danhSachDonVi.forEach((item, index) => {
        this.danhSachDonVi[index].check = false
        return item.id
      })
    },
    onPrint() {
      window.frames.print_frame.document.title = document.title
      window.frames.print_frame.document.body.innerHTML = `<!DOCTYPE html>
      <html>
        <title> In báo cáo thống kê </title>
        <head>
        <style>
            th.ve-table-header-th {
  background-color: #fafafa;
  padding: 10px;
  font-weight: 500;
  color: #000000d9;
  font-size: 14px;
  background-color: #f3f4f7 !important;
    border-bottom: 1px solid #dcdfe6 !important;
    border-right: 1px solid #dcdfe6 !important;
}
.empty-data {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 100%;
  color: #666;
  font-size: 16px;
  border: 1px solid #eee;
  border-top: 0;
}
tbody td {
text-align: center;
background-color: #fff;
color: #000000d9;
height: inherit;
font-size: 14px;
border-right: 1px solid #eee;
border-bottom: 1px solid #eee;
border-collapse: separate;
border-spacing: 0;
padding: 5px;
}
table {
border: 1px solid #dcdfe6;
border-right: none !important;
border-bottom: none !important;
}
        </style>
        </head>
        <body>
          <div>
            <div class="text">
              <div style="display: flex">
                <div style="width: 33.3%" class="ooc">
                    <h4>
                      Biểu số 06a/VPCP/KSTT
                    </h4>
                </div>
                <div style="width: 33.3%">
                  <div style="text-align: center">
                    <h4>TÌNH HÌNH, KẾT QUẢ GIẢI QUYẾT THỦ TỤC HÀNH CHÍNH TẠI CƠ QUAN,</h4>
                    <h4>ĐƠN VỊ  GIẢI QUYẾT THỦ TỤC HÀNH CHÍNH</h4>
                  </div>
                </div>
              </div>
              <div>
                <h4 style="text-align: center">
                      Kỳ báo cáo : ${this.getTimeSelected()}
                </h4>
                <h4 style="text-align: center">
                      (Từ ngày ${formatDate(this.filter.tuNgay)} đến ngày ${formatDate(this.filter.denNgay)})
                </h4>
                <h4 style="text-align: right">
                      Đơn vị tính : Số hồ sơ TTHC
                </h4>
              </div>
            </div>
            ${this.$refs.docBody.innerHTML}
          </div>
        </body>
      </html>`
      window.frames.print_frame.window.focus()
      window.frames.print_frame.window.print()
    },
    getTimeSelected() {
      switch (this.activeTimeSelect) {
        case 1: return 'Tháng'
        case 2: return 'Quý'
        case 3: return 'Năm'
        case 4: return 'Tuỳ chọn'
        default: return '(Theo tiêu chí thời gian được chọn)'
      }
    },
    layDanhSachHoSo(hoSoId) {
      if (hoSoId) {
        this.dataHoSoId = hoSoId
      }
      let payload = {
        dataHoSoId: this.dataHoSoId,
        dataTTHCId: [],
        dataLinhVucId: [],
        fulltextsearch: '',
        chuHoSo: '',
        maHoSo: '',
        diaChiChuHoSo: '',
        thoiGianTiepNhanTu: '',
        thoiGianTiepNhanDen: '',
        thoiGianHenTraTu: '',
        thoiGianHenTraDen: '',
        trangThaiHoSo: [],
        loaiTrangThaiHoSo: [],
        loaiTrangThaiConHanQuaHan: 0,
        trangThaiConHanQuaHan: 0,
        navCode: '',
        pageNumber: 1,
        pageSize: 10,
      }
      if (this.$refs.hoSo) {
        payload = {
          ...payload,
          ...this.$refs.hoSo.getCurrentPage(),
        }
      }
      this.$nextTick(() => {
        this.$axios.post(END_POINTS.BAO_CAO_THONG_KE.DANH_SACH_HO_SO, payload, false).then(res => {
          if (res.data && res.data.succeeded) {
            this.rows = res.data.data
            this.tongSoHoSo = res.data.totalCount
            this.$refs.commonModal.showModal()
          }
        })
      })
    },
  },
}
</script>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  .card .card-header {
    border-bottom: 1.5px solid #EEEEEE !important;
    display: inline-block;
    padding: 0.5rem 1.5rem !important;
    margin: 0;
  }
  .text-form{
    font-size:12px;
    font-weight:bold;
  }
  .text{
    padding-top:5rem;
  }
  .selectYear{
    width:10%;
    height:37px;
    border-radius:4px;
    border: 1px solid #d8d6de;
    padding-left:5px;
    color:gray;
    outline:none;
  }
  .border-card{
    margin-bottom:-0.8rem !important;
  }
  .outline-white{
    background: #FFFFFF !important;
  }
  .card-body{
    padding-top:10px;
  }
  .btn{
    border: 1px solid #ddd;
  }
  .body-card{
    max-height: 140px;
    overflow-x : hidden;
  }
  .body-card::-webkit-scrollbar {
    width: 3px;
    background-color:#E5E5E5;
}
.body-card::-webkit-scrollbar-thumb {
    background-color: gray;
}
.body-card::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color:#ffff;
}
  .text-link{
    color:#1270BE;
    text-decoration: underline;
    cursor:pointer;
  }
  .custom-control-inline{
    margin-bottom:15px;
  }
  .custom-cell {
    color:#1270BE;
    cursor:pointer;
    text-align:right;
  }
  .text-check-right {
    text-align: right !important;
  }
  .text-check {
    text-align: left !important;
  }
</style>
